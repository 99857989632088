<template>
  <div>
    <data-iterator :headers="payloadHeaders" :payload="payload" class="ma-2"></data-iterator>
    <v-dialog v-model="drawer" width="400" persistent>
      <v-card>
        <v-card-title class="primary darken-2 white--text pa-4 pb-3">
          <span class="body-1 font-weight-medium"> {{ modelObj._id ? 'Update subject' : 'Add subject'}} </span>
        </v-card-title>
        <v-card-text class="pa-2 pb-1">
          <v-form ref="form">
            <v-container fluid>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field
                    outlined dense hide-details label="Code" v-model="modelObj.code"
                    :rules="setValidation('requiredValidation')" validate-on-blur
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-3">
                  <v-text-field outlined dense hide-details label="Name" v-model="modelObj.name"
                  :rules="setValidation('requiredValidation')" validate-on-blur></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-checkbox outlined dense hide-details label="Is Active" v-model="modelObj.isactive" class="ma-0 d-inline-flex"></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile color="success" class="mx-2" @click="saveUpdateHandler"> {{ modelObj._id ? 'Update' : 'Save' }} </v-btn>
          <v-btn tile @click="drawer = false; $refs.form.reset();" color="error"> Cancel </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      drawer: false,
      listOfBooks: [],
      modelObj: {},
      payload: {
        moduleIcon: 'mdi-book',
        create: () => {
          this.modelObj = { isactive: true }
          this.drawer = true
        },
        showBackBtn: () => this.$router.go(-1),
        items: [],
        showSelect: false,
        actionsList: [{
          click: (item) => this.editHandler(item._id),
          icon: 'mdi-pencil',
          color: 'primary'
        }, {
          click: (item) => this.deleteHandler(item._id),
          icon: 'mdi-delete',
          color: 'error'
        }]
      }
    }
  },
  components: {
    'data-iterator': () => import('@/components/DataIterator.vue')
  },
  watch: {
    drawer(val) {
      if (!val) this.modelObj = {}
    }
  },
  beforeMount () {
    this.getListHandler()
  },
  computed: {
    payloadHeaders () {
      return [{
        text: 'Code',
        align: 'start',
        sortable: false,
        value: 'code'
      }, {
        text: 'Name',
        value: 'name'
      }, {
        text: 'Is Active',
        value: 'isactive',
        type: 'BOOLEAN'
      }, {
        text: 'Actions',
        value: 'actions',
        width: '20%'
      }]
    }
  },
  methods: {
    getListHandler () {
      this.$_execute('get', 'subjects')
        .then(({ data }) => {
          this.payload.items = data
        })
    },
    saveUpdateHandler () {
      if (this.$refs.form && this.$refs.form.validate()) {
        let url = this.modelObj._id ? `subjects/${this.modelObj._id}` : `/subjects`
        let method = this.modelObj._id ? `put` : `post`
        this.$_execute(method, url, this.modelObj)
          .then(() => {
            this.$root.$emit('snackbar', { message: this.modelObj._id ? 'Updated Successfully' : 'Saved Successfully', color: 'success'})
            this.$refs.form.reset()
            this.modelObj = {}
            this.drawer = false
            this.getListHandler()
          })
      } else {
        this.$root.$emit('snackbar', {
          color: 'error',
          message: 'Please check the required fields!'
        })
      }
    },
    editHandler (id) {
      this.$_execute('get', `subjects/${id}`).then(response => {
        this.modelObj = response.data[0]
        this.drawer = true
      })
    },
    deleteHandler (id) {
      this.$root.$emit('delete', {
        module: 'subjects',
        _id: id,
        onResolve: () => {
          this.dialog = false
          this.$root.$emit('snackbar', { message: `Deleted Successfully`, color: 'success' })
          this.getListHandler()
        }
      })
    }
  }
}
</script>
